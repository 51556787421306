<template>
  <div>
    <div class="asset-item">
      <div class="place-items-start">
        <p-dropdown
          v-model="selectedBank"
          :options="banks"
          size="medium"
          menu-width="100%"
          is-icon-cdn
        />
      </div>

      <div class="place-items-between mb-6 py-2 mt-8">
        <div>
          <span class="f-body-12 t-fg-secondary" v-text="$t('assetItem.buyerName')" />
          <h4 class="f-utility-14-medium t-fg-primary" v-text="$t('general.title')" />
        </div>
        <p-copy :copy-text="$t('general.title')" />
      </div>

      <div class="place-items-between py-2">
        <div>
          <span class="f-body-12 t-fg-secondary" v-text="$t('assetItem.iban')" />
          <h4 class="f-utility-14-medium t-fg-primary" v-text="selectedBank?.iban" />
        </div>
        <p-copy :copy-text="selectedBank?.iban" />
      </div>

      <div class="place-items-center p-24 my-24">
        <nuxt-img :src="qrCodeIban" alt="IBAN QR Code" width="160" height="160" />
      </div>

      <div>
        <div class="f-body-16-medium t-fg-primary" v-text="$t('assetItem.importantInfo')" />
        <p-info-card class="flex-column border-none">
          <ul class="asset-item__card">
            <li
              v-for="item in depositTlInfo"
              :key="item.id"
              class="asset-item__card--list"
              v-text="item.text"
            />
          </ul>
        </p-info-card>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { AppSettingsBankAccountList } from '~/models/main'

const props = defineProps({
  currency: {
    type: String,
    default: 'tl',
  },
  selectedMarket: {
    type: Object,
    default: null,
  },
  icon: {
    type: String,
    default: '',
  },
})

const mainStore = useMainStore()
const userStore = useUserStore()

const selectedBank = ref<string[] | null>([])
const qrCodeIban = ref<string>('')
const userAddress = ref<{ address: string }[]>([])
const selectedNetwork = ref<string | null>(null)
const qrCodeAddress = ref<string>('')
const locale = import.meta.env.VITE_DEFAULT_LOCALE as string

const { $qrcode } = useNuxtApp()

const banks = computed<AppSettingsBankAccountList>(() => {
  const bankAccounts = mainStore.getBankAccounts?.[props.currency] || []
  return $_.map(bankAccounts, (item: { name: any }) => ({
    ...$_.omit(item, 'name'),
    value: item.name,
    title: item.name,
  })).sort((a: { value: string }, b: { value: any }) => {
    return a.value.localeCompare(b.value, locale, { sensitivity: 'base' })
  })
})

const networks = computed(() => {
  return $_.map(props.selectedMarket?.networks, (item: string) => ({
    ...mainStore.getNetwork(item),
    label: mainStore.getNetwork(item)?.name,
    value: mainStore.getNetwork(item)?.network_type,
  }))
})

const generateQRCode = (data: any, target: globalThis.Ref<string, string>) => {
  $qrcode.toDataURL(data, { width: 160, margin: 2 }, (err: any, url: any) => {
    if (err) return err
    else target.value = url
  })
}

const getAddresses = async () => {
  const addresses = await userStore.getUserAddresses
  const depositItems = $_.filter(addresses, { direction: 'deposit' })
  userAddress.value = depositItems.length ? depositItems : await assignDepositAddress()
  generateQRCode(userAddress.value[0]?.address, qrCodeAddress)
}

const assignDepositAddress = async () => {
  const response = await userStore.assignDepositAddress({
    currency: props.currency,
    network: props.currency,
  })
  return (response as { payload: { address: string } })?.payload?.address
}

watch(
  [() => props.depositAsset, selectedBank, selectedNetwork],
  ([newDepositAsset, newSelectedBank, newSelectedNetwork]) => {
    if (newDepositAsset || newSelectedNetwork) getAddresses()
    if (newSelectedBank?.iban) generateQRCode(newSelectedBank.iban, qrCodeIban)
  },
)

watch([() => banks.value], () => {
  selectedBank.value = banks.value[0]
  selectedNetwork.value = networks.value[0]?.label
})

onMounted(() => {
  selectedBank.value = banks.value[0]
  selectedNetwork.value = networks.value[0]?.label
})

const depositTlInfo = [
  { id: 1, text: $t('assetItem.depositTlInfo.1') },
  { id: 2, text: $t('assetItem.depositTlInfo.2') },
  { id: 3, text: $t('assetItem.depositTlInfo.3') },
  { id: 4, text: $t('assetItem.depositTlInfo.4') },
  { id: 5, text: $t('assetItem.depositTlInfo.5') },
  { id: 6, text: $t('assetItem.depositTlInfo.6') },
  { id: 7, text: $t('assetItem.depositTlInfo.7') },
  { id: 8, text: $t('assetItem.depositTlInfo.8') },
]
</script>

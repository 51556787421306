<template>
  <div class="deposit">
    <p-dropdown
      v-model="userData.network"
      name="network"
      :label="$t('formElementName.chooseInvestmentNetwork')"
      :options="networksData"
      :status="networksData.length === 1 ? 'readonly' : 'selectable'"
    />
    <div class="d-flex flex-column gap-24">
      <div class="place-items-center full-width">
        <div id="qrAddress" />
      </div>
      <div class="d-flex gap-12">
        <div class="f-utility-14 t-fg-primary word-break-all" v-text="address" />
        <p-copy :copy-text="address" />
      </div>
    </div>
    <p-info-card v-if="networkName">
      <div
        class="asset-item__info"
        v-html="
          $t('assetItem.transferInfo', {
            currency: selectedMarket?.pairs?.market?.toUpperCase?.(),
            selectedCurrency: networkName.replace(/\s*\(.*?\)/, ''),
          })
        "
      />
    </p-info-card>
    <div class="full-width d-flex flex-column gap-12">
      <div>
        <p class="f-body-16-medium t-fg-primary" v-text="$t('wallet.deposit.importantInfo')" />
        <div class="p-info-card__icon p-info-card__icon__success" />
      </div>
      <div>
        <ul class="asset-item__card">
          <li v-for="item in depositAssetInfo" :key="item.id" class="asset-item__card--list">
            {{ item.text }}
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useForm } from 'vee-validate'
import QrCodeStyling from 'qr-code-styling'

const props = defineProps({
  currency: {
    type: String,
    default: 'tl',
  },
  selectedMarket: {
    type: Object,
    default: null,
  },
})

const userStore = useUserStore()
const mainStore = useMainStore()

const selectedMarketCurrency = computed(() => mainStore.getCurrency(props.currency as string))
const networkName = ref('')

const userData = ref({
  network: { value: '', title: '', desc: '' },
  nameAndSurname: '',
  identityNumber: '',
})

const schema = {
  network: 'required',
  nameAndSurname: {
    requiredGroup: {
      label: $t('formElementName.nameAndSurname'),
    },
  },
  identityNumber: {
    requiredGroup: {
      label: $t('formElementName.identityNumber'),
    },
  },
}

useForm({
  validationSchema: schema,
})

const networksData = computed(() => {
  const freeMatrixCurrency = mainStore.getFreeMatrix[props.selectedMarket.pairs?.market]
  const networks = selectedMarketCurrency.value?.networks
  return $_.map(networks, (item: any) => ({
    title: mainStore.getNetworks[item]?.name,
    value: mainStore.getNetworks[item].network_type,
    desc: $t('wallet.deposit.networkShootingCost', {
      fee: `${freeMatrixCurrency[item][0][props.selectedMarket.pairs?.market]} ${props.currency.toUpperCase()}`,
    }),
  }))
})

const address = computed(() => {
  networkName.value = mainStore.getNetworks[userData.value.network.value]?.name
  const addressesArray = Object.values(userStore?.getUserAddresses || []) as any

  return (
    addressesArray.find(
      ({ direction, network_type: networkType }: any) =>
        direction === 'deposit' && networkType === userData.value.network.value,
    )?.address || ''
  )
})

const img = computed(() => getAsset({ asset: selectedMarketCurrency.value?.icon, type: 'coin' }))

const depositAssetInfo = [
  { id: 1, text: $t('assetItem.depositAssetInfo.1') },
  { id: 2, text: $t('assetItem.depositAssetInfo.2') },
]

const qrGenerate = () => {
  const canvasEl = document.getElementById('qrAddress') as HTMLElement
  const qrCode = new QrCodeStyling({
    width: 145,
    height: 145,
    data: address.value,
    image: img.value,
    cornersSquareOptions: {
      color: '#000000',
      type: 'extra-rounded',
    },
    imageOptions: {
      crossOrigin: 'anonymous',
      imageSize: 0.3,
      hideBackgroundDots: true,
    },
  })
  canvasEl.innerHTML = ''
  qrCode.append(canvasEl)
}

watch(
  () => address.value,
  () => {
    qrGenerate()
  },
)

watch(
  () => networksData.value,
  () => {
    userData.value.network.value = networksData.value[0]?.value
    userData.value.network.title = networksData.value[0]?.title
  },
)

onMounted(() => {
  userData.value.network.value = networksData.value[0]?.value
  userData.value.network.title = networksData.value[0]?.title
})
</script>
